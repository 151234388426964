import KycKeys from "../../enums/kyc-enum";

const civilIDDetailsData = {
  [KycKeys.CIVIL_ID_NUMBER]: {
    value: "",
    error: "",
  },
  [KycKeys.CIVIL_ID_SERIAL]: {
    value: "",
    error: "",
  },
};
export const civilIDDetailsRenewalData = {
  [KycKeys.CIVIL_ID_NUMBER]: {
    value: "",
    error: "",
  },
};

export default civilIDDetailsData;
