import React from "react";
import styles from "./chip.module.scss";
import PropTypes from "prop-types";

const Chip = ({ text, backgroudColor }) => (
  <div className={styles.container} style={{ backgroundColor: backgroudColor }}>
    <span className={styles.title}>{text}</span>
  </div>
);

export default Chip;
Chip.propTypes = {
  text: PropTypes.string.isRequired,
  backgroudColor: PropTypes.string.isRequired,
};
