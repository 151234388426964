import React, { useEffect, useState } from "react";
import styles from "./navbar.module.scss";
import LanguageBox from "../../widgets/language-box";
import { useTranslation } from "react-i18next";
import { getLanguage, setLanguage } from "../../utils";
import { languageOptions } from "../../config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetStateOnLogout,
  setKycFields,
  setCountries,
} from "../../store/slices/general.slice";
import {
  civildIDetailsRenewalRoute,
  landingRoute,
} from "../../routes/routes.const";
import { getCustomer, putLanguage } from "../../general-services.proxy";
import { getToken } from "../../utils/auth";
import LogoutBox from "../logout-box";
import { languageEnum } from "../../enums/language-enum";
import logo from "../../assets/icons/nbkwealth-logo.svg";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguage());
  const { kycFieldsAr, kycFieldsEn, countriesAr, countriesEn } = useSelector(
    (state) => state.general
  );
  const token = getToken();
  const { i18n } = useTranslation();
  const options = languageOptions;

  const changeKycOptionsLanguage = (language) => {
    if (language === languageEnum.arabic) {
      dispatch(setKycFields(kycFieldsAr));
      return;
    }
    dispatch(setKycFields(kycFieldsEn));
  };

  const changeCountriesLanguage = (language) => {
    if (language === languageEnum.arabic) {
      dispatch(setCountries(countriesAr));
      return;
    }
    dispatch(setCountries(countriesEn));
  };

  const updateLanguage = async (language) => {
    try {
      await putLanguage(language);
    } catch (exception) {
      console.error(exception);
    }
  };

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    setLanguage(value);
    setSelectedLanguage(value);
    changeKycOptionsLanguage(value);
    changeCountriesLanguage(value);
    if (token) {
      updateLanguage(value);
    }
  };

  const handleLogoutAction = () => {
    sessionStorage.clear();
    dispatch(resetStateOnLogout());
    if (location.pathname.includes("/renewal")) {
      navigate(civildIDetailsRenewalRoute);
    } else {
      navigate(landingRoute);
    }
  };

  const fetchCustomerData = async () => {
    try {
      const { language } = await getCustomer();

      if (language) {
        i18n.changeLanguage(language);
        setLanguage(language);
        setSelectedLanguage(language);
      }
    } catch (exception) {
      console.error(exception);
    }
  };

  useEffect(() => {
    if (token) {
      fetchCustomerData();
    }
  }, [token]);

  return (
    <div className={styles.navbar}>
      <img src={logo} />
      <div className={styles.items}>
        <div className={styles.selectDiv}>
          <LanguageBox
            inputOptions={options}
            value={selectedLanguage}
            onChange={handleLanguageChange}
          />
        </div>
        {token && (
          <div className={styles.selectDiv}>
            <LogoutBox onClick={handleLogoutAction} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
