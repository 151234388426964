import documentsIcon from "../../assets/icons/documents-rounded-icon.svg";
import profileIcon from "../../assets/icons/rounded-profile-icon.svg";
import documentsSignedIcon from "../../assets/icons/documents-signed-rounded-icon.svg";
import doneIcon from "../../assets/icons/done-rounded-icon.svg";

export const IDENTITY_VERIFICATION_ID = "identity_verification";
export const COMPLETE_PROFILE_ID = "complete_profile";
export const REVIEW_SIGN_ID = "review_and_sign";
export const UPLOAD_DOCUMENT_ID = "upload_documents";

const journeyLocale = {
  identityVerification: "identityVerification",
  identityVerificationSubtitle: "identityVerificationSubtitle",
  completeYourProfile: "completeYourProfile",
  completeYourProfileSubtitle: "completeYourProfileSubtitle",
  reviewAndSignYourDocuments: "reviewAndSignYourDocuments",
  reviewAndSignYourDocumentsSubtitle: "reviewAndSignYourDocumentsSubtitle",
  updateCivilID: "updateCivilID",
  updateCivilIDSubtitle: "updateCivilIDSubtitle",
  reviewAndUpdate: "reviewAndUpdate",
  reviewAndUpdateDescription: "reviewAndUpdateDescription",
  uploadSupportingDocuments: "uploadSupportingDocuments",
  uploadSupportingDocumentsSubtitle: "uploadSupportingDocumentsSubtitle",
  uploadSupportingDocumentSkipSubtitle: "uploadSupportingDocumentSkipSubtitle",

  updateCivilIDDone: "updateCivilIDDone",
  updateCivilIDSubtitleDone: "updateCivilIDSubtitleDone",
  reviewAndUpdateDone: "reviewAndUpdateDone",
  reviewAndUpdateDescriptionDone: "reviewAndUpdateDescriptionDone",
  uploadSupportingDocumentsDone: "uploadSupportingDocumentsDone",
  uploadSupportingDocumentsSubtitleDone:
    "uploadSupportingDocumentsSubtitleDone",
};
export const timeLineConfig = [
  {
    id: IDENTITY_VERIFICATION_ID,
    title: journeyLocale.identityVerification,
    subTitle: journeyLocale.identityVerificationSubtitle,
    icon: profileIcon,
    doneIcon: doneIcon,
    isDone: false,
  },
  {
    id: COMPLETE_PROFILE_ID,
    title: journeyLocale.completeYourProfile,
    subTitle: journeyLocale.completeYourProfileSubtitle,
    icon: documentsIcon,
    doneIcon: doneIcon,
    isDone: false,
  },
  {
    id: REVIEW_SIGN_ID,
    title: journeyLocale.reviewAndSignYourDocuments,
    subTitle: journeyLocale.reviewAndSignYourDocumentsSubtitle,
    icon: documentsSignedIcon,
    doneIcon: doneIcon,
    isDone: false,
  },
];

export const timeLineRenewalConfig = [
  {
    id: IDENTITY_VERIFICATION_ID,
    title: journeyLocale.updateCivilID,
    subTitle: journeyLocale.updateCivilIDSubtitle,
    icon: profileIcon,
    doneIcon: doneIcon,
    isDone: false,
    titleDone: journeyLocale.updateCivilIDDone,
    subTitleDone: journeyLocale.updateCivilIDSubtitleDone,
  },
  {
    id: COMPLETE_PROFILE_ID,
    title: journeyLocale.reviewAndUpdate,
    subTitle: journeyLocale.reviewAndUpdateDescription,
    icon: documentsIcon,
    doneIcon: doneIcon,
    isDone: false,
    titleDone: journeyLocale.reviewAndUpdateDone,
    subTitleDone: journeyLocale.reviewAndUpdateDescriptionDone,
  },
  {
    id: UPLOAD_DOCUMENT_ID,
    title: journeyLocale.uploadSupportingDocuments,
    subTitle: journeyLocale.uploadSupportingDocumentsSubtitle,
    subTitleForSkip: journeyLocale.uploadSupportingDocumentSkipSubtitle,
    icon: documentsSignedIcon,
    doneIcon: doneIcon,
    isDone: false,
    titleDone: journeyLocale.uploadSupportingDocumentsDone,
    subTitleDone: journeyLocale.uploadSupportingDocumentsSubtitleDone,
    optional: true,
  },
];
