import React from "react";
import styles from "./additional-document-button.module.scss";
import PropTypes from "prop-types";
import { acceptedFileTypes } from "../../../../config";
import plusIcon from "../../../../assets/icons/plus-icon.svg";
import { useTranslation } from "react-i18next";
import { CLIENT_ADDITIONAL_FILE } from "../../config";

const AdditionalDocumentButton = ({
  handleFileSelection,
  additionalDocumentError,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.additionalDocumentButton}>
      <input
        data-testid={"additional_document"}
        id={CLIENT_ADDITIONAL_FILE}
        name={CLIENT_ADDITIONAL_FILE}
        className={styles.fileUploadInput}
        type="file"
        accept={acceptedFileTypes}
        onChange={(e) => handleFileSelection(e, true)}
      />
      <img src={plusIcon} alt="img" />
      <span> {t("additionalDocument")}</span>
      {additionalDocumentError && (
        <span className={styles.errorMessage}>
          {t(additionalDocumentError)}
        </span>
      )}
    </div>
  );
};

export default AdditionalDocumentButton;
AdditionalDocumentButton.prototype = {
  handleFileSelection: PropTypes.func.isRequired,
  additionalDocumentError: PropTypes.func.isRequired,
};
