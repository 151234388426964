import React, { useEffect } from "react";
import ChildFirstName from "./child-first-name";
import ChildMiddleName from "./child-middle-name";
import ChildFamilyName from "./child-family-name";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";

const ChildrenDetails = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.CHILDREN_DETAILS;
  const childrenNumberKey = KycKeys.CHILDREN_NUMBER;
  const childrenNameKey = KycKeys.CHILDREN_NAME;

  const {
    [fieldKey]: { value: childrensNameList },
    [childrenNumberKey]: { value: childrenNumberValue },
  } = data;

  const isVisible = childrensNameList.length === childrenNumberValue;
  const renderDynamicFields = () =>
    Array.from({ length: childrenNumberValue }, (_, index) => {
      const counter = index + 1;
      const fieldData = data[fieldKey].value;
      return (
        <>
          <ChildFirstName
            counter={counter}
            data={{
              [childrenNameKey]: fieldData[index],
              [fieldKey]: childrensNameList,
            }}
            setKycData={setKycData}
            translator={translator}
          />
          <ChildMiddleName
            counter={counter}
            data={{
              [childrenNameKey]: fieldData[index],
              [fieldKey]: childrensNameList,
            }}
            setKycData={setKycData}
            translator={translator}
          />
          <ChildFamilyName
            counter={counter}
            data={{
              [childrenNameKey]: fieldData[index],
              [fieldKey]: childrensNameList,
            }}
            setKycData={setKycData}
            translator={translator}
          />
        </>
      );
    });

  if (!isVisible) {
    return;
  }

  return renderDynamicFields();
};

ChildrenDetails.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default ChildrenDetails;
