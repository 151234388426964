import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "../accordion.module.scss";
import { useNavigate } from "react-router-dom";
import arrowDown from "../../../assets/icons/arrow-down.svg";
import editIcon from "../../../assets/icons/edit-icon.svg";
import missingInfoIcon from "../../../assets/icons/red-info-missing-icon.svg";
import { useTranslation } from "react-i18next";
import {
  identityVerificationRenewalRoute,
  uploadAdditionalDocumentsRoute,
} from "../../../routes/routes.const";
import { SUPPORTING_DOCUMENTS } from "../../../pages/kyc-summary-step/renewal/config";
import {
  CLIENT_ADDITIONAL_FILE,
  documentsNameMapper,
} from "../../../pages/upload-additional-documents/config";
import { customerRiskEnums, returnReasons } from "../../../config";

const Accordion = ({
  accordionTitle,
  accordionData,
  routeName,
  testId,
  additionalInfo,
  missingFieldsArray,
  orderId,
  isEditDisabled,
  order,
  riskLevel,
  defaultAccordionOpen,
  isCivilIdExpired,
}) => {
  const contentHeight = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const [isOpen, setIsOpen] = useState(false);

  const handleEditClick = (event) => {
    event.stopPropagation();
    if (isEditDisabled) {
      return;
    }

    if (
      routeName === uploadAdditionalDocumentsRoute ||
      routeName === identityVerificationRenewalRoute
    ) {
      navigate(
        {
          pathname: routeName,
        },
        {
          state: {
            orderId: orderId,
            isKyc: true,
          },
        }
      );
    } else {
      navigate({
        pathname: routeName,
      });
    }
  };

  const handleItemClick = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const displayAccordionDetailsImages = () => (
    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
      {accordionData?.map((imageObj, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "column" }}>
          <p className={styles.fieldLabel}>{imageObj.title}</p>
          <div className={styles.imageBox}>
            <img src={imageObj.url} />
          </div>
        </div>
      ))}
    </div>
  );
  const displayAccordionSupportingDocuments = () => {
    const documentSections = accordionData.map((section, index) => {
      const sectionTitle = Object.keys(section)[0];
      const documentArray = section[sectionTitle];

      return (
        <div key={index} className={styles.documentSection}>
          <p className={styles.fieldLabel}>
            {sectionTitle === SUPPORTING_DOCUMENTS
              ? t("documentsNeeded")
              : t("additionalDocuments")}
          </p>

          {documentArray && documentArray.length > 0 ? (
            documentArray.map((doc, docIndex) => (
              <div key={docIndex} className={styles.documentItem}>
                <p className={styles.documentItemText}>
                  {doc.documentType === CLIENT_ADDITIONAL_FILE
                    ? doc.documentName
                    : t(`${documentsNameMapper[doc.documentType]}`)}
                </p>
              </div>
            ))
          ) : (
            <p>{t("noDocumentsLabel")}</p>
          )}
        </div>
      );
    });

    return (
      <div className={styles.supportingDocumentsContainer}>
        {documentSections}
      </div>
    );
  };

  const displayAccordionDetails = (missingFieldsArray) =>
    accordionData?.map((item) => {
      const isMissing = Object.values(missingFieldsArray.validations).some(
        (fieldsArray) => fieldsArray.includes(item.id)
      );

      return (
        <div
          key={item.id}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <span className={styles.fieldLabel}>
              {item.label === "childFullName"
                ? `${t(item.label)} (${item.childNumber})`
                : t(`${item.label}`)}
            </span>
            <span className={styles.fieldValue}>
              {Array.isArray(item.value)
                ? item.value.join(", ")
                : item.value === "NALabel"
                  ? t(`${item.value}`)
                  : item.value}
            </span>
          </div>

          {isMissing && <img src={missingInfoIcon} alt="Missing Information" />}
        </div>
      );
    });

  const getAccordionContent = (accordionTitle, missingFieldsArray) => {
    const translatedTitle = t(accordionTitle);

    const accordionActions = {
      [t("civilIdInfoStepTitle")]: () => displayAccordionDetailsImages(),
      [t("supportingDocumentsInfoStepTitle")]: () =>
        displayAccordionSupportingDocuments(),
    };

    return (
      accordionActions[translatedTitle] ||
      (() => displayAccordionDetails(missingFieldsArray))
    )();
  };

  useEffect(() => {
    if (t(defaultAccordionOpen) === accordionTitle) {
      setIsOpen(true);
    }
  }, [defaultAccordionOpen]);

  if (
    accordionTitle === t("supportingDocumentsInfoStepTitle") &&
    (!order.isKycSubmitted ||
      (order.isKycSubmitted &&
        riskLevel === customerRiskEnums.LOW &&
        order.returnReason === returnReasons.KYC_AND_DOCUMENTS) ||
      (order.isKycSubmitted &&
        riskLevel === customerRiskEnums.LOW &&
        !order.isReturnedRequest))
  ) {
    return null;
  }

  if (accordionTitle === t("civilIdInfoStepTitle") && !isCivilIdExpired) {
    return null;
  }

  return (
    <div
      className={`${
        additionalInfo !== 0 ? styles.noPaddingBottomwrapper : styles.wrapper
      } `}
      onClick={handleItemClick}
      data-testid="accordion-wrapper"
    >
      <div
        className={`${
          additionalInfo !== 0
            ? styles.noPaddingAccordionTitleContainer
            : styles.accordionTitleContainer
        } ${isOpen ? styles.active : ""}`}
      >
        <p className={styles.accordionTitle}>{accordionTitle}</p>
        <div className={styles.headerActions}>
          {!isEditDisabled && (
            <div className={`${styles.editAction}`} onClick={handleEditClick}>
              <img src={editIcon} alt="Edit Icon" />
              <div className={`${styles.editButton}`}>
                {t("editButtonLabel")}
              </div>
            </div>
          )}
          <img
            data-testid={`arrow${testId}`}
            src={arrowDown}
            alt="Arrow"
            className={`${styles.arrow} ${isOpen ? styles.active : ""}`}
          />
        </div>
      </div>
      {additionalInfo !== 0 && (
        <div className={styles.missingFieldsText}>
          {additionalInfo}{" "}
          {additionalInfo === 1 ? t("missingField") : t("missingFields")}
        </div>
      )}
      {isOpen && <hr className={styles.hr} />}
      <div
        ref={contentHeight}
        className={styles.answerContainer}
        style={
          isOpen
            ? { height: contentHeight.current.scrollHeight }
            : { height: "0px", overflow: "hidden" }
        }
        data-testid="content-container"
      >
        {getAccordionContent(accordionTitle, missingFieldsArray)}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  accordionTitle: PropTypes.string.isRequired,
  accordionData: PropTypes.array.isRequired,
  routeName: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  additionalInfo: PropTypes.number.isRequired,
  missingFieldsArray: PropTypes.object.isRequired,
  orderID: PropTypes.string,
  defaultAccordionOpen: PropTypes.string,
  isCivilIdExpired: PropTypes.bool,
};

export default Accordion;
