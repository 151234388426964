import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./document-card.module.scss";
import { acceptedFileTypes } from "../../../../config";
import doneIcon from "../../../../assets/icons/done-rounded-icon.svg";
import deleteIcon from "../../../../assets/icons/delete-icon.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DocumentCard = ({
  document,
  index,
  handleFileSelection,
  handleViewDocument,
  isAdditionalDocument,
  handleDeleteClick,
}) => {
  const { t } = useTranslation("common");

  return (
    <div key={index} data-testid={document.documentType}>
      <div
        data-testid={`${document.documentType}-card`}
        key={index}
        className={styles.stepContainer}
        onClick={() => {
          if (document.documentId) {
            handleViewDocument(
              document.documentId,
              document.contentType,
              document.documentName
            );
          }
        }}
      >
        {!document.documentId && (
          <input
            data-testid={`${document.documentType}-input`}
            id={document.documentType}
            name={document.documentType}
            className={styles.fileUploadInput}
            type="file"
            accept={acceptedFileTypes}
            onChange={(e) => handleFileSelection(e)}
          />
        )}
        {isAdditionalDocument ? (
          <span className={styles.additionalDocumentTitle}>
            {document.documentName}
          </span>
        ) : (
          <div className={styles.stepContent}>
            {document.documentId ? (
              <img src={doneIcon} className={styles.doneIcon} />
            ) : (
              <div className={styles.stepOrder}>0{t(`${index + 1}`)}</div>
            )}
            <div>
              <h3 className={styles.stepTitle}>
                {t(`${document.documentName}`)}
              </h3>
              <p className={styles.stepSubTitle}>
                {t(`${document.documentDescription}`)}
              </p>
            </div>
          </div>
        )}

        {document.documentId && (
          <div className={styles.tooltipContainer}>
            <img
              data-testid={`${document.documentType}-delete`}
              src={deleteIcon}
              onClick={(e) => {
                if (document.isDisabled) {
                  return;
                }
                handleDeleteClick(e, document.documentId);
              }}
            />
          </div>
        )}
      </div>
      {document.error && (
        <span className={styles.errorMessage}>{t(document.error)}</span>
      )}
    </div>
  );
};

export default DocumentCard;
DocumentCard.prototype = {
  document: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setDocuments: PropTypes.func.isRequired,
  handleFileSelection: PropTypes.func.isRequired,
  handleViewDocument: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,

  isAdditionalDocument: PropTypes.bool,
};
