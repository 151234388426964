import React from "react";
import styles from "./session-expired.module.scss";
import closeIcon from "../../assets/icons/close-icon.svg";
import { useTranslation } from "react-i18next";
import "../../../src/theme/spacing.scss";
import PrimaryButton from "../../widgets/buttons/primary-button";
import { useDispatch } from "react-redux";
import {
  resetStateOnLogout,
  setShowSessionExpiredModal,
} from "../../store/slices/general.slice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  civildIDetailsRenewalRoute,
  landingRoute,
} from "../../routes/routes.const";

const SessionExpired = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("common");

  const handleClick = () => {
    sessionStorage.clear();
    dispatch(resetStateOnLogout());
    dispatch(setShowSessionExpiredModal(false));
    if (location.pathname.includes("/renewal")) {
      navigate(civildIDetailsRenewalRoute);
    } else {
      navigate(landingRoute);
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <div className={styles.container}>
          <button
            className={styles.closeButton}
            onClick={() => {
              handleClick();
            }}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
        <div className={styles.description}>
          <span className={styles.title}>{t("sessionExpired")}</span>
          <div className={styles.buttondiv}>
            <PrimaryButton
              onClick={() => {
                handleClick();
              }}
            >
              {t("goToLogin")}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
