import kycFields from "./config/kyc";
import KycKeys from "./enums/kyc-enum";

export const serializeCountriesAndCitiesResponse = (response) => {
  const cities = response.countries_en.cities;
  const countriesEn = response.countries_en.countries;
  const countriesAr = response.countries_ar.countries;

  return { cities, countriesEn, countriesAr };
};

export const deserializeKycData = (data, category) => {
  const deserializedKycData = {};
  Object.entries(data).forEach(([key, entry]) => {
    const { value: kycValue, step: currentTab, isExcluded } = entry;
    if (
      kycValue === null ||
      !kycValue.toString().length ||
      currentTab != category ||
      isExcluded
    ) {
      return;
    }
    deserializedKycData[key] = kycValue;
  });

  return { category: category, kyc_info: deserializedKycData };
};

export const serializeKycData = (data) => {
  const { kyc_info: kycInfo } = data;
  const serializedData = { ...kycFields };
  Object.entries(kycInfo).forEach(([key, value]) => {
    if (value === null || !value.toString().length) {
      return;
    }

    serializedData[key] = {
      ...kycFields[key],
      value,
      isVisible:
        value || value === 0 || value === false || value.length > 0
          ? true
          : Boolean(value),
    };
  });
  return serializedData;
};

export const serializeCustomerResponse = (data) => {
  const {
    customer_id: customerId,
    status: status,
    is_nbkc_client: isNbkcClient,
    kyc_info: {
      civil_id_number: civilIdNumber,
      civil_id_serial: civilIDserialNumber,
      country_code: countryCode,
      contact_mobile_number: mobileNumber,
      email: email,
      civil_id_expiry_date: civilIdExpiryDate,
    },
    order_id: orderId,
    order_kyc_id: orderKycId,
    customer_settings: { language, is_email_updated: isEmailUpdated },
    order_services: orderServices,
    client_request_status: clientRequestStatus,
  } = data;
  return {
    customerId,
    status,
    isNbkcClient,
    orderId,
    orderKycId,
    language,
    isEmailUpdated,
    civilIdNumber,
    countryCode,
    mobileNumber,
    civilIDserialNumber,
    email,
    civilIdExpiryDate,
    orderServices,
    clientRequestStatus,
  };
};

export const serializeCustomerStatusResponse = (data) => {
  const { customer_id: customerId, customer_status: customerStatus } = data;
  return {
    customerId,
    customerStatus,
  };
};

export const serializeCustomerOrderResponse = (data) => {
  const {
    order_id: orderId,
    documents_details: {
      is_civil_id_back_uploaded: isCivilIdBackUploaded,
      is_civil_id_front_uploaded: isCivilIdFrontUploaded,
      is_civil_id_selfie_uploaded: isCivilIdSelfieUploaded,
    },
    order_details: {
      is_kyc_submitted: isKycSubmitted,
      is_kyc_done: isKycDone,
      order_kyc_id: orderKycId,
      order_brokerage_id: orderBrokerageId,
      is_returned_request: isReturnedRequest,
      return_reason: returnReason,
      return_description: returnDescription,
      product_type: productType,
      brokerage_account_type: brokerageAccountType,
      is_contract_signed: isContractSigned,
      client_request_id: clientRequestId,
      is_documents_submitted: isDocumentsSubmitted,
    },
  } = data;
  return {
    orderId,
    isCivilIdBackUploaded,
    isCivilIdFrontUploaded,
    isCivilIdSelfieUploaded,
    isKycSubmitted,
    isKycDone,
    orderKycId,
    orderBrokerageId,
    isReturnedRequest,
    returnReason,
    productType,
    brokerageAccountType,
    isContractSigned,
    returnDescription,
    clientRequestId,
    isDocumentsSubmitted,
  };
};

export const serializePostCustomerResponse = (data) => {
  const {
    customer_id: customerId,
    kyc_info: {
      civil_id_number: civilIdNumber,
      civil_id_serial: civilIdSerial,
      country_code: countryCode,
      contact_mobile_number: contactMobileNumber,
    },
  } = data;
  return {
    customerId,
    civilIdNumber,
    civilIdSerial,
    countryCode,
    contactMobileNumber,
  };
};

export const serializeVerifyOTPResponse = (data) => {
  const {
    customer_id: customerId,
    access_token: accessToken,
    expire_at: createdAt,
  } = data;
  return {
    customerId,
    accessToken,
    createdAt,
  };
};

export const serializeCustomerMediumsResponse = (data) => {
  const { customer_id: customerId, available_mediums: availableMediums } = data;
  return { customerId, availableMediums };
};

export const serializedKycValidationResponse = (data) => {
  const { validations } = data;

  const {
    summary_tab: summaryTab,
    product_info: productInfo,
    personal_info: personalInfo,
    contact_info: contactInfo,
    employment,
    income_and_wealth: incomeAndWealth,
    additional_info: additionalInfo,
  } = validations;

  return {
    validations: {
      summaryTab,
      productInfo,
      personalInfo,
      contactInfo,
      employment,
      incomeAndWealth,
      additionalInfo,
    },
  };
};

export const serializeKycDataV2 = (data) => {
  const { kyc_info: kycInfo, created_at, updated_at } = data;

  const serializedData = {
    ...kycFields,
    [KycKeys.CIVIL_ID_SERIAL]: {
      ...kycFields[KycKeys.CIVIL_ID_SERIAL],
      isDisabled: false,
    },
  };

  Object.entries(kycInfo).forEach(([key, value]) => {
    if (value === null || !value.toString().length) {
      return;
    }

    serializedData[key] = {
      ...kycFields[key],
      value,
      isVisible:
        value || value === 0 || value === false || value.length > 0
          ? true
          : Boolean(value),
    };
  });

  const result = {
    serializedData,
    createdAt: created_at,
    updatedAt: updated_at,
  };

  return result;
};

export const serializeGetCustomerRiskResponse = (data) => {
  const { risk_level: riskLevel } = data;
  return {
    riskLevel,
  };
};

export const serializeGetDocumentsResponse = (data) =>
  data.documents.map((response) => {
    const {
      id: id,
      document_type: documentType,
      document_name: documentName,
      content_type: contentType,
    } = response;

    return {
      id,
      documentType,
      documentName,
      contentType,
    };
  });
