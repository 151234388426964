import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  identityVerificationRenewalConfig,
  BACK_CIVIL_ID,
  FRONT_CIVIL_ID,
  maxUploadedFileSize,
} from "../config";
import PrimaryButton from "../../../widgets/buttons/primary-button";
import { areAllStepsDone } from "../../../helpers/general-helpers";
import {
  journeyTimeLineRenewalRoute,
  kycRenewalSummaryStepRoute,
} from "../../../routes/routes.const";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainLayout from "../../../layouts/main-layout";
import { setHideNavigation } from "../../../store/slices/general.slice";
import styles from "../components/verification-steps/verification-steps.module.scss";
import {
  postPresignedUrl,
  uploadToS3Buckets,
  postDocument,
  getPresignedUrl,
} from "../identity-verification-services.proxy";
import CircularLoader from "../../../widgets/circular-loader";
import { validateUploadedFile, getAcceptedFileTypesById } from "../helper";
import ViewDocumnetModal from "../../../modals/view-document";
import {
  deleteDocument,
  getAllCustomerDocuments,
} from "../../../general-services.proxy";

const IdentityVerificationRenewal = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationData, setVerificationData] = useState(
    identityVerificationRenewalConfig
  );
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [documentName, setDocumentName] = useState("");

  const [documentUrl, setDocumentUrl] = useState("");
  const { orderId = "", isKyc = false } = location.state;

  const onClick = () => {
    if (isKyc) {
      navigate({
        pathname: kycRenewalSummaryStepRoute,
      });
    } else {
      navigate(journeyTimeLineRenewalRoute, {
        replace: true,
      });
    }
  };

  const handleCloseClick = () => {
    dispatch(setHideNavigation(false));
    setOverlayVisible(false);
  };

  const updateVerificationData = (fileIdToUpdate, propertiesToUpdate) => {
    setVerificationData((prev) =>
      prev.map((item) =>
        item.id === fileIdToUpdate ? { ...item, ...propertiesToUpdate } : item
      )
    );
  };

  const createDocument = async (file, fileType, fileId) => {
    try {
      setIsUploadLoading(true);
      const { path, url } = await postPresignedUrl(fileType);
      await uploadToS3Buckets(url, file, fileType);
      await postDocument(orderId, path, fileId, fileType);

      setRefetch(!refetch);
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsUploadLoading(false);
    }
  };

  const handleViewDocument = async (documentId, documentTitle) => {
    try {
      const { url } = await getPresignedUrl(documentId);
      setDocumentName(documentTitle);
      setDocumentUrl(url);
      setOverlayVisible(true);
    } catch (exception) {
      console.error(exception);
    }
  };

  const validateFile = (file, allowedFormats) =>
    validateUploadedFile(file, maxUploadedFileSize, allowedFormats);

  const handleInvalidFile = (fileId, error) => {
    updateVerificationData(fileId, { error, isDone: false });
  };

  const handleValidFile = (file, fileId) => {
    updateVerificationData(fileId, { error: "" });
    createDocument(file, file.type, fileId);
  };

  const handleFileSelection = (e) => {
    const file = e.target.files[0];
    const fileId = e.target.name;

    if (!file) {
      return;
    }

    const allowedFormats = getAcceptedFileTypesById(verificationData, fileId);
    const { isValid, error } = validateFile(file, allowedFormats);

    if (!isValid) {
      handleInvalidFile(fileId, error);
      return;
    }

    handleValidFile(file, fileId);
  };

  const handleIconClick = async (e, documentId) => {
    e.stopPropagation();
    try {
      setIsUploadLoading(true);
      await deleteDocument(orderId, documentId);
      setRefetch(!refetch);
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsUploadLoading(false);
    }
  };

  const renderVerificationSteps = () =>
    verificationData.map((step, index) => (
      <div key={step.id} data-testid={step.id}>
        <div
          key={index}
          className={styles.stepContainer}
          style={{
            cursor: step.isDisabled ? "default" : "pointer",
            backgroundColor: step.isDisabled ? "rgba(244, 244, 244, 0.9)" : "",
          }}
          onClick={() => {
            if (step.documentId) {
              handleViewDocument(step.documentId, step.documentName);
            }
          }}
        >
          {!step.isDisabled && !step.documentId && (
            <input
              data-testid={`capture-${step.id}`}
              id={step.id}
              name={step.id}
              className={styles.fileUploadInput}
              type="file"
              accept={step.acceptedFileTypes}
              onChange={(e) => handleFileSelection(e)}
            />
          )}
          <div className={styles.stepContent}>
            {step.isDone ? (
              <img src={step.stepDoneIcon} className={styles.doneIcon} />
            ) : (
              <div
                className={`${styles.stepOrder} ${step.isDisabled ? styles.disabled : ""}`}
              >
                0{t(`${step.order}`)}
              </div>
            )}
            <div>
              <h3
                className={`${styles.stepTitle} ${step.isDisabled ? styles.disabled : ""}`}
              >
                {t(`${step.title}`)}
              </h3>
              <p
                className={`${styles.stepSubTitle} ${step.isDisabled ? styles.disabled : ""}`}
              >
                {t(`${step.subTitle}`)}
              </p>
            </div>
          </div>
          {step.documentId && (
            <div
              className={`${styles.tooltipContainer} ${step.isDisabled ? styles.disabled : ""}`}
            >
              <img
                src={step.deleteIcon}
                data-testid={`delete_${step.id}`}
                onClick={(e) => {
                  if (step.isDisabled) {
                    return;
                  }
                  handleIconClick(e, step.documentId);
                }}
              />
            </div>
          )}
        </div>
        {step.error && (
          <span className={styles.errorMessage}>{t(step.error)}</span>
        )}
      </div>
    ));

  const renderFooter = () => {
    const isAllStepsDone = areAllStepsDone(verificationData);
    const buttonText = isKyc
      ? t("saveAndContinueToSummary")
      : t("saveAndContinue");
    return (
      <PrimaryButton
        onClick={onClick}
        disabled={!isAllStepsDone}
        data-testid="submit"
      >
        {buttonText}
      </PrimaryButton>
    );
  };

  const fetchDocuments = async () => {
    try {
      setIsUploadLoading(true);
      const response = await getAllCustomerDocuments(orderId);

      const civilIDFrontDocument = response.find(
        (doc) => doc.documentType === FRONT_CIVIL_ID
      );

      updateVerificationData(FRONT_CIVIL_ID, {
        isDone: civilIDFrontDocument?.id ? true : false,
        documentId: civilIDFrontDocument ? civilIDFrontDocument.id : null,
      });

      const civilIDBackDocument = response.find(
        (doc) => doc.documentType === BACK_CIVIL_ID
      );

      updateVerificationData(BACK_CIVIL_ID, {
        isDone: civilIDBackDocument?.id ? true : false,
        documentId: civilIDBackDocument ? civilIDBackDocument.id : null,
      });
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsUploadLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchDocuments();
    }
  }, [orderId, refetch]);

  return (
    <MainLayout title={t("civilIdTitle")} footer={renderFooter}>
      {isUploadLoading && <CircularLoader />}
      <div className={styles.verificationStepsContainer} />
      {renderVerificationSteps()}
      {isOverlayVisible && (
        <ViewDocumnetModal
          title={documentName}
          presignedUrl={documentUrl}
          handleCloseClick={handleCloseClick}
        />
      )}
    </MainLayout>
  );
};

export default IdentityVerificationRenewal;
