import React, { useState, useEffect } from "react";
import styles from "./view-document.module.scss";
import closeIcon from "../../assets/icons/close-icon.svg";
import useWindowWidth from "../../custom-hooks/useWindonWidth";
import mobileCloseIcon from "../../assets/icons/white-close-icon.svg";
import { useTranslation } from "react-i18next";
import "../../../src/theme/spacing.scss";
import PDFViewer from "../../components/pdf-viewer";

const ViewDocumnetModal = ({
  title,
  presignedUrl,
  handleCloseClick,
  fileType,
}) => {
  const windowWidth = useWindowWidth();
  const { i18n, t } = useTranslation("common");
  const [direction, setDirection] = useState("rtl");

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    setDirection(dir);
  }, [i18n, i18n.language]);

  const getDirection = () =>
    direction === "ltr"
      ? {
          right: "var(--extra-large-spacing)",
        }
      : {
          left: "var(--extra-large-spacing)",
        };

  return (
    <>
      {windowWidth > 700 ? (
        <div className={styles.overlay} onClick={handleCloseClick}>
          <div className={styles.content} onClick={(e) => e.stopPropagation()}>
            <div className={styles.container}>
              <span className={styles.title}>{t(title)}</span>
              <button
                className={styles.closeButton}
                style={getDirection()}
                data-testid="closeIcon"
              >
                <img src={closeIcon} alt="Close" onClick={handleCloseClick} />
              </button>
            </div>
            <div>
              {fileType === "application/pdf" ? (
                <PDFViewer pdfUrl={presignedUrl} />
              ) : (
                <img
                  src={presignedUrl}
                  alt="CivilIDInfo"
                  className={styles.imageStyle}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.overlay} onClick={handleCloseClick}>
          <div onClick={(e) => e.stopPropagation()}>
            {fileType === "application/pdf" ? (
              <PDFViewer pdfUrl={presignedUrl} />
            ) : (
              <img src={presignedUrl} alt="CivilIDInfo" />
            )}
            <button className={styles.closeButtonMobile}>
              <img
                src={mobileCloseIcon}
                alt="Close"
                onClick={handleCloseClick}
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewDocumnetModal;
