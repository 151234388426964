import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layouts/main-layout";
import styles from "./loading-component.module.scss";

const LoadingComponent = () => {
  const { t } = useTranslation("common");

  return (
    <MainLayout>
      <div className={styles.container}>
        <div className={styles.loadingContainer}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
        </div>
        <span className={styles.title}>{t("loadingTitle")}</span>
        <span className={styles.subtitleMessage}>{t("loadingSubtitle")}</span>
      </div>
    </MainLayout>
  );
};

export default LoadingComponent;
