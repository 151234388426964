import React, { useState } from "react";
import MainLayout from "../../../layouts/main-layout";
import PrimaryButton from "../../../widgets/buttons/primary-button";
import CivilIdField from "../fields/civil-id-number";
import { alertMessagesMapping } from "../../../config";
import "../../../../src/theme/spacing.scss";
import { useNavigate } from "react-router-dom";
import {
  notRenewalRoute,
  otpVerificationRenewal,
} from "../../../routes/routes.const";
import styles from "../civil-id-details.module.scss";
import { civilIDDetailsRenewalData } from "../config";
import KycKeys from "../../../enums/kyc-enum";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import errorCloseIcon from "../../../assets/icons/alert-close-icon.svg";
import errorInfoIcon from "../../../assets/icons/alert-info-icon.svg";
import Alert from "../../../components/alert-component-fixed";
import {
  setUserInfo,
  setAvailableMediums,
} from "../../../store/slices/general.slice";
import { getCustomerMediums } from "../civil-id-details.proxy";
import { sendAndResendOTP } from "../../../general-services.proxy";

const CivilIdDetailsRenewal = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [civilIDFields, setCivilIdFields] = useState(civilIDDetailsRenewalData);
  const [exception, setException] = useState("");
  const { userInfo } = useSelector((state) => state.general);
  const {
    [KycKeys.CIVIL_ID_NUMBER]: { value: civilIdValue },
  } = civilIDFields;

  const isContinueDisabled = Object.keys(civilIDFields).some(
    (key) => !civilIDFields[key].value || civilIDFields[key].error || isLoading
  );

  const customerPayload = {
    civil_id_number: civilIdValue,
  };

  const customerSendOTPPayload = {
    civil_id_number: civilIdValue,
  };

  const sendCustomerOTP = async () => {
    try {
      setIsLoading(true);
      await sendAndResendOTP(customerSendOTPPayload);
    } catch (exception) {
      throw exception;
    } finally {
      setIsLoading(false);
    }
  };

  const checkCustomerMediumsStatus = async () => {
    try {
      setIsLoading(true);
      const response = await getCustomerMediums(customerPayload);

      if (response.customerId) {
        await sendCustomerOTP();
        dispatch(setAvailableMediums(response.availableMediums));
        navigate(otpVerificationRenewal);
      } else {
        navigate(notRenewalRoute);
      }
    } catch (exception) {
      setException(exception);
      throw new Error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  const onContinue = async () => {
    dispatch(
      setUserInfo({
        ...userInfo,
        civilIDNumber: civilIdValue,
      })
    );

    setException("");
    try {
      await checkCustomerMediumsStatus();
    } catch (exception) {
      console.error(exception);
    }
  };

  const renderFooter = () => (
    <PrimaryButton
      onClick={onContinue}
      disabled={isContinueDisabled}
      data-testid="getStarted"
    >
      {t("getStarted")}
    </PrimaryButton>
  );

  const getAlertMessage = () => {
    if (!exception || !exception.response || !exception.response.data) {
      return;
    }
    const { description } = exception.response.data;

    if (!description) {
      return;
    }

    const translationKey = alertMessagesMapping[description];

    return translationKey
      ? t(translationKey)
      : exception.response.data.description;
  };

  return (
    <>
      <MainLayout
        title={t("updateYourInformation")}
        subTitle={t("updateYourInformationSubtitle")}
        footer={renderFooter}
        withAlertMessage={exception}
      >
        <div className={styles.container}>
          <CivilIdField
            data={{
              [KycKeys.CIVIL_ID_NUMBER]: civilIDFields[KycKeys.CIVIL_ID_NUMBER],
            }}
            setData={setCivilIdFields}
            setException={setException}
          />
        </div>
      </MainLayout>

      {exception && (
        <Alert
          text={getAlertMessage()}
          setText={setException}
          leftIcon={errorInfoIcon}
          rightIcon={errorCloseIcon}
          styling={{ backGroundColor: "#ffedf0", textColor: "#e94a66" }}
        />
      )}
    </>
  );
};

export default CivilIdDetailsRenewal;
