import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PrimaryButton from "../../widgets/buttons/primary-button";
import { journeyTimeLineRenewalRoute } from "../../routes/routes.const";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layouts/main-layout";
import InfoRenewalBox from "../../components/info-box/renewal";
import styles from "./upload-additional-documents.module.scss";
import DocumentCard from "./components/document-card";
import {
  CLIENT_ADDITIONAL_FILE,
  documentsDescriptionMapper,
  documentsNameMapper,
  documentTypesToBeFiltered,
  maxUploadedFileSize,
} from "./config";
import CircularLoader from "../../widgets/circular-loader";
import {
  allowedFileTypes,
  customerRiskEnums,
  returnReasons,
} from "../../config";
import ViewDocumnetModal from "../../modals/view-document";
import { validateUploadedFile } from "./helper";
import { setHideNavigation } from "../../store/slices/general.slice";
import { useDispatch } from "react-redux";
import AdditionalDocumentButton from "./components/additional-document-button";
import {
  deleteDocument,
  getAllCustomerDocuments,
  getCustomerOrder,
  getCustomerRisk,
  putCustomerOrder,
} from "../../general-services.proxy";
import {
  getPresignedUrl,
  postDocument,
  postPresignedUrl,
  uploadToS3Buckets,
} from "../identity-verification/identity-verification-services.proxy";

const UploadAdditionalDocuments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("common");
  const [documentNeeded, setDocumentsNeeded] = useState([]);
  const [documentAdditional, setDocumentsAdditional] = useState([]);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [additionalDocumentError, setAdditionalDocumentError] = useState("");
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const { orderId = "" } = location.state;

  const [documentUrl, setDocumentUrl] = useState("");
  const [documentContentType, setDocumentContentType] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [order, setOrder] = useState("");

  const [isReturnedOrder, setIsReturnedOrder] = useState(false);
  const [returnReason, setReturnReason] = useState("");
  const [customerRisk, setCustomerRisk] = useState("");

  const [returnDescription, setReturnDescription] = useState("");

  const isDocumentsOptional = !isReturnedOrder;

  const isContinueDisabled =
    (isReturnedOrder && documentNeeded.find((doc) => !doc.documentId)) ||
    (isReturnedOrder &&
      returnReason === returnReasons.SUPPORTING_DOCUMENTS &&
      customerRisk === customerRiskEnums.LOW &&
      !documentAdditional.find((doc) => doc.documentId));
  const isNoDocumentsUploaded =
    !documentNeeded.find((doc) => doc.documentId) &&
    !documentAdditional.find((doc) => doc.documentId);

  const validateFile = (file, allowedFormats) =>
    validateUploadedFile(file, maxUploadedFileSize, allowedFormats);

  const updateDocumentsData = (fileIdToUpdate, propertiesToUpdate) => {
    setDocumentsNeeded((prev) =>
      prev.map((item) =>
        item.documentType === fileIdToUpdate
          ? { ...item, ...propertiesToUpdate }
          : item
      )
    );
  };

  const handleCloseClick = () => {
    dispatch(setHideNavigation(false));
    setOverlayVisible(false);
  };

  const handleInvalidFile = (fileId, error) => {
    updateDocumentsData(fileId, { error });
  };

  const createDocument = async (file, fileType, fileId, isAdditional) => {
    try {
      setIsUploadLoading(true);
      const { path, url } = await postPresignedUrl(fileType);
      await uploadToS3Buckets(url, file, fileType);
      const fileName = isAdditional ? file.name : null;
      await postDocument(orderId, path, fileId, fileType, fileName);

      setIsRefetch(!isRefetch);
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsUploadLoading(false);
    }
  };

  const handleValidFile = (file, fileId, isAdditional) => {
    if (isAdditional) {
      setAdditionalDocumentError("");
    } else {
      updateDocumentsData(fileId, { error: "" });
    }
    createDocument(file, file.type, fileId, isAdditional);
  };

  const handleFileSelection = (e, isAdditional = false) => {
    const file = e.target.files[0];
    const fileId = e.target.name;

    if (!file) {
      return;
    }

    const { isValid, error } = validateFile(file, allowedFileTypes);

    if (!isValid && !isAdditional) {
      handleInvalidFile(fileId, error);
      return;
    }

    if (!isValid && isAdditional) {
      setAdditionalDocumentError(error);
      return;
    }

    handleValidFile(file, fileId, isAdditional);
    e.target.value = null;
  };

  const handleViewDocument = async (documentId, contentType, documentName) => {
    try {
      const { url } = await getPresignedUrl(documentId);
      if (!allowedFileTypes.includes(contentType)) {
        window.open(url);
        return;
      }
      setDocumentContentType(contentType);
      setDocumentName(documentName);
      setDocumentUrl(url);
      setOverlayVisible(true);
    } catch (exception) {
      console.error(exception);
    }
  };

  const handleSkipForNowClick = () => {
    navigate(journeyTimeLineRenewalRoute, {
      state: {
        documentSkipForNow: true,
      },
    });
  };

  const handleDeleteClick = async (e, documentId) => {
    e.stopPropagation();
    try {
      setIsUploadLoading(true);
      await deleteDocument(orderId, documentId);
      setIsRefetch(!isRefetch);
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsUploadLoading(false);
    }
  };

  const updateDocumentsToDone = async () => {
    try {
      await putCustomerOrder(orderId, true);
      navigate(journeyTimeLineRenewalRoute, {
        replace: true,
      });
    } catch (exception) {
      console.error(exception);
    }
  };

  const renderFooter = () => (
    <div className={styles.footerContainer}>
      <PrimaryButton
        onClick={updateDocumentsToDone}
        disabled={isContinueDisabled || isNoDocumentsUploaded}
        data-testid="submit"
      >
        {t("saveAndContinue")}
      </PrimaryButton>
      {isDocumentsOptional ? (
        <span className={styles.skipForNow} onClick={handleSkipForNowClick}>
          {t("skipForNow")}
        </span>
      ) : (
        <></>
      )}
    </div>
  );

  const getFilterCriteria = (doc) => {
    if (
      returnReason === returnReasons.SUPPORTING_DOCUMENTS &&
      customerRisk === customerRiskEnums.LOW
    ) {
      return documentTypesToBeFiltered.includes(doc.documentType) && doc.id;
    }
    return documentTypesToBeFiltered.includes(doc.documentType);
  };

  const fetchDocuments = async () => {
    try {
      setIsUploadLoading(true);
      const response = await getAllCustomerDocuments(orderId);

      const documentsNeededFiltered = response
        .filter((doc) => getFilterCriteria(doc))
        .map((doc) => ({
          documentId: doc.id,
          documentType: doc.documentType,
          documentName: documentsNameMapper[doc.documentType],
          documentDescription: documentsDescriptionMapper[doc.documentType],
          contentType: doc.contentType,
        }));
      setDocumentsNeeded(documentsNeededFiltered);
      const documentsAdditionalFiltered = response
        .filter((doc) => doc.documentType === CLIENT_ADDITIONAL_FILE)
        .map((doc) => ({
          documentId: doc.id,
          documentType: doc.documentType,
          documentName: doc.documentName,
          contentType: doc.contentType,
        }));
      setDocumentsAdditional(documentsAdditionalFiltered);
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsUploadLoading(false);
      setIsLoading(false);
    }
  };

  const fetchOrder = async () => {
    setIsLoading(true);
    try {
      const order = await getCustomerOrder(orderId);
      const { riskLevel } = await getCustomerRisk(order.orderKycId);
      setCustomerRisk(riskLevel);
      setOrder(order);
      setReturnDescription(order.returnDescription);
      setReturnReason(order.returnReason);
      setIsReturnedOrder(order.isReturnedRequest);
    } catch (exception) {
      console.error(exception);
    }
  };

  useEffect(() => {
    if (order) {
      fetchDocuments();
    }
  }, [order, isRefetch]);

  useEffect(() => {
    if (orderId) {
      fetchOrder();
    }
  }, [orderId]);

  return (
    <MainLayout
      title={t("uploadSupportingDocuments")}
      footer={renderFooter}
      isOptional={isDocumentsOptional}
    >
      {(isUploadLoading || isLoading) && <CircularLoader />}

      <div className={styles.container}>
        {returnReason === returnReasons.SUPPORTING_DOCUMENTS ? (
          <InfoRenewalBox
            title={t("attentionRequired")}
            beforeDescription={t("beforedescription")}
            description={returnDescription}
          />
        ) : (
          <InfoRenewalBox
            title={t("additionalDocumentsAlert")}
            subTitle={t("additionalDocumentsAlertSubtitle")}
            color="#F59E0B"
            borderColor="#F59E0B"
          />
        )}
        <div className={styles.documentsNeededContainer}>
          {documentNeeded.length > 0 && (
            <span className={styles.documentNeedTitle}>
              {t("documentsNeeded")}
            </span>
          )}

          {documentNeeded.map((document, index) => (
            <DocumentCard
              document={document}
              index={index}
              setIsUploadLoading={setIsUploadLoading}
              handleFileSelection={handleFileSelection}
              handleViewDocument={handleViewDocument}
              handleDeleteClick={handleDeleteClick}
            />
          ))}
        </div>
        <div className={styles.documentsAdditionalContainer}>
          <span className={styles.documentNeedTitle}>
            {t("additionalDocuments")}
          </span>
          {documentAdditional.map((document, index) => (
            <DocumentCard
              document={document}
              index={index}
              setIsUploadLoading={setIsUploadLoading}
              handleFileSelection={handleFileSelection}
              handleViewDocument={handleViewDocument}
              handleDeleteClick={handleDeleteClick}
              isAdditionalDocument
            />
          ))}
          <AdditionalDocumentButton
            handleFileSelection={handleFileSelection}
            additionalDocumentError={additionalDocumentError}
          />
          <span className={styles.note}> {t("sizeAndFormat")}</span>
        </div>
        {isOverlayVisible && (
          <ViewDocumnetModal
            title={documentName}
            presignedUrl={documentUrl}
            handleCloseClick={handleCloseClick}
            fileType={documentContentType}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default UploadAdditionalDocuments;
