import { generalEnums } from "../enums/general";

const convertBooleanToYesNo = (value) => {
  if (value) {
    return generalEnums.YES;
  } else if (value === false) {
    return generalEnums.NO;
  } else {
    return "";
  }
};

const convertYesNoToBoolean = (value) => {
  const mapping = {
    [generalEnums.YES]: true,
    [generalEnums.NO]: false,
  };

  return mapping[value] ?? null;
};

export const calculateKycProgress = (kycInfo) => {
  const visibleFields = Object.values(kycInfo).filter(
    (field) => field.isVisible && !field.isDisabled
  );

  const totalFields = visibleFields.length;

  const filledFields = visibleFields.filter((field) => {
    const isNonEmpty =
      field.value !== null &&
      field.value !== undefined &&
      field.value !== "" &&
      !(Array.isArray(field.value) && field.value.length === 0);

    const isFalseOrZero = field.value === false || field.value === 0;

    const isFilled = isNonEmpty || isFalseOrZero;

    return isFilled;
  });

  return totalFields > 0 ? (filledFields.length / totalFields) * 100 : 0;
};

export { convertBooleanToYesNo, convertYesNoToBoolean };
