import React, { useEffect, useState } from "react";
import PrimaryButton from "../../widgets/buttons/primary-button";
import MainLayout from "../../layouts/main-layout";
import styles from "./sucess-page.module.scss";
import PropTypes from "prop-types";
import { getCustomer } from "../../general-services.proxy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SuccessPage = ({
  icon,
  title,
  subTitle,
  emailConfiramtionRoute,
  journeyTimelineSuccessRoute,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [isEmailUpdated, setIsEmailUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCustomerData = async () => {
    try {
      const { isEmailUpdated } = await getCustomer();
      setIsEmailUpdated(isEmailUpdated);
    } catch (exception) {
      console.error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFooter = () => {
    const onContinue = () => {
      if (isEmailUpdated) {
        navigate(journeyTimelineSuccessRoute, {
          replace: true,
        });
      } else {
        navigate(emailConfiramtionRoute, {
          replace: true,
        });
      }
    };
    return (
      <PrimaryButton
        onClick={onContinue}
        disabled={isLoading}
        data-testid={"continue"}
      >
        {t("continue")}
      </PrimaryButton>
    );
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  return (
    <MainLayout footer={renderFooter}>
      <div className={styles.container}>
        <img className={styles.imageStyle} src={icon} alt="Success" />
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitleMessage}>{subTitle}</span>
      </div>
    </MainLayout>
  );
};

SuccessPage.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default SuccessPage;
