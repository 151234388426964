import axiosInstance from "../../network/axios-instance";
import { serializeVerifyOTPResponse } from "../../serializer";
import {
  PostVerifyOTPUrl,
  PostResendOTPUrl,
  PostVerifyRenewalOTPUrl,
} from "./otp-verification.const";

export const verifyOTP = async (payload) => {
  try {
    const response = await axiosInstance.post(PostVerifyOTPUrl, payload);
    return serializeVerifyOTPResponse(response.data) || {};
  } catch (exception) {
    throw exception;
  }
};

export const resendOTP = async (payload) => {
  try {
    const response = await axiosInstance.post(PostResendOTPUrl, payload);
    return response.data || {};
  } catch (exception) {
    throw exception;
  }
};

export const verifyOTPRenewal = async (payload) => {
  try {
    const response = await axiosInstance.post(PostVerifyRenewalOTPUrl, payload);
    return serializeVerifyOTPResponse(response.data) || {};
  } catch (exception) {
    throw exception;
  }
};
