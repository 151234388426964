import React from "react";
import styles from "./banner.module.scss";
import PropTypes from "prop-types";

const Banner = ({ backgroundColor, textColor, text, icon }) => (
  <div
    data-testid={"banner"}
    className={styles.container}
    style={{ backgroundColor: backgroundColor }}
  >
    <img src={icon} alt="alert" />
    <span className={styles.text} style={{ color: textColor }}>
      {text}
    </span>
  </div>
);

export default Banner;
Banner.propTypes = {
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};
