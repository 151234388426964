export const documentsNameMapper = {
  source_income_investments: "sourceIncomeInvestement",
  source_income_job: "sourceIncomeJob",
  source_income_private_business: "sourceIncomePB",
  source_income_real_estate: "sourceIncomeRealEstate",
  source_income_pension: "sourceIncomePension",
  source_income_other: "sourceIncomeOther",
  wealth_source_saving: "wealthSourceSaving",
  wealth_source_job_indemnity: "wealthSourceJobIndeminity",
  wealth_source_inheritance: "wealthSourceInheritance",
  wealth_source_investments: "wealthSourceInvestments",
  wealth_source_sale_of_property: "wealthSourceSaleProprety",
  wealth_source_other: "wealthSourceOther",
};

export const documentsDescriptionMapper = {
  source_income_investments: "sourceIncomeInvestementSub",
  source_income_job: "sourceIncomeJobSub",
  source_income_private_business: "sourceIncomePBSub",
  source_income_real_estate: "sourceIncomeRealEstateSub",
  source_income_pension: "sourceIncomePensionSub",
  source_income_other: "sourceIncomeOtherSub",
  wealth_source_saving: "wealthSourceSavingSub",
  wealth_source_job_indemnity: "wealthSourceJobIndeminitySub",
  wealth_source_inheritance: "wealthSourceInheritanceSub",
  wealth_source_investments: "wealthSourceInvestmentsSub",
  wealth_source_sale_of_property: "wealthSourceSalePropretySub",
  wealth_source_other: "wealthSourceOtherSub",
};
export const documentTypesToBeFiltered = [
  "wealth_source_saving",
  "wealth_source_inheritance",
  "wealth_source_job_indemnity",
  "wealth_source_sale_of_property",
  "wealth_source_investments",
  "wealth_source_other",
  "wealth_source_salary",
  "wealth_source_loan",
  "wealth_source_fixed_deposit",
  "source_income_pension",
  "source_income_private_business",
  "source_income_job",
  "source_income_investments",
  "source_income_real_estate",
  "source_income_other",
];
export const CLIENT_ADDITIONAL_FILE = "client_additional_file";
export const maxUploadedFileSize = 20971520;
