import React from "react";
import successIcon from "../../assets/icons/sucess-icon.svg";
import SuccessPage from "../../components/success-component";
import { useTranslation } from "react-i18next";
import {
  emailAddressDetailsRoute,
  journeyTimeLineRoute,
} from "../../routes/routes.const";

const OTPSuccess = () => {
  const { t } = useTranslation("common");
  return (
    <SuccessPage
      icon={successIcon}
      title={t("successOTP")}
      subTitle={t("verifiedMessage")}
      emailConfiramtionRoute={emailAddressDetailsRoute}
      journeyTimelineSuccessRoute={journeyTimeLineRoute}
    />
  );
};

export default OTPSuccess;
