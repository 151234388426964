export const termsinEnPart = `The Customer hereby acknowledges his legal competence for contracting.

 

The Customer hereby confirms that the information provided herein is accurate, correct and complete. The Customer undertakes to inform Watani Investment Company K.S.C.C. (“Company”) in writing of any changes to the information already provided and to update the information on this form periodically and whenever requested to do so by the Company so long as the Customer is a client of the Company and availing of the Company’s services. The Customer further acknowledges that the Company shall cease to render its services in respect of portfolio management to the Customer if it fails to provide the requested information as per this Form or to update the same within three (3) working days from date of notification by the Company. The Customer further declares that the Company shall not be responsible for mail not delivered to the above given mailing address or mishandling of mail for reasons beyond the control of the Company.

 

The Customer hereby declares that, in the event of him/her being subject to any foreign tax laws requiring information to be shared by the Company with the foreign government to whose tax laws the Customer may be subject, or with its representatives, the Customer explicitly agrees to Company’s full compliance with such foreign government’s instructions and requests for information without Company’s need to notify the Customer or to seek the Customer’s additional consent. The Customer understands and acknowledges that this declaration is with respect to all of Company’s products and account types and covers transactions, balances, supporting information, and any enquiries from the requesting government or its representatives.

 

To facilitate Company’s compliance with the foreign tax laws to which the Customer may be subject, the Customer hereby undertakes to provide the Company with any documentation requested by the foreign government or its representatives. The Customer further agrees to provide and/or update the above-referenced documents as and when required by the terms and conditions set by the foreign tax authority to which the Customer may be subject.

 

The Customer authorizes the Company to share with and obtain from National Bank of Kuwait (“NBK”) or any of its subsidiaries any information or document related to the Customer to the extent the Customer is a client of NBK or its subsidiaries including without limitations copies of the KYC forms, FATCA Forms (including W9), CRS self-certification and such other identification documents, statements of accounts, documents supporting the source of wealth or income or any other documents.

The Customer understands and agrees that this declaration is final and irrevocable, and that it is not subject to cancellation or amendments.`;

export const termsinArPart = `يقر العميل - بموجب هذا - بأهليته القانونية للتعاقد.

 

يؤكد العميل - بموجب هذا - أن المعلومات المقدمة في هذا الطلب دقيقة وصحيحة وكاملة. ويتعهد العميل بأن يبلغ شركة الوطني للاستثمار ش.م.ك.م (الشركة) كتابة عن أية تغيرات تطرأ على المعلومات المقدمة وتحديثها على هذا النموذج بشكل دوري ومتى طلبت الشركة ذلك طالما أن العميل لا يزال عميلاً لدى الشركة ويستفيد من خدماتها. كما يقر العميل أيضاً بأن الشركة ستتوقف عن تقديم خدماتها,بالنسبة إلى إدارة المحافظ ,إلى العميل في حال لم يقم العميل بتزويدها بالمعلومات المطلوبة وفقاً لهذا النموذج أو تحديث المعلومات خلال ثلاثة (3) أيام عمل من تاريخ إخطار من قبل الشركة. كما يقر العميل أيضاً بأن الشركة غير مسئولة في حال عدم استلام البريد على العنوان المذكور سابقاً أو سوء معالجة البريد لأسباب خارجة عن نطاق سيطرة الشركة.

 

ويقر العميل كذلك - بموجب هذا - بأنه في حال خضوعه لقوانين ضريبية أجنبية تتطلب تقديم معلومات من الشركة  للحكومة الأجنبية التي قد يكون خاضعاً لقوانينها الضريبية أو تقديمها لممثليها . فإنه يوافق صراحة على امتثال الشركة - امتثالاً تاماً - لتعليمات الحكومة الأجنبية ولطلبها لتلك المعلومات ودون حاجة لأن تخطره الشركة بذلك ولا أن تحصل منه على موافقة إضافية. يفهم العميل ويقر بأن هذا الإقرار يسري على كل منتجات الشركة وكافة أنواع الحسابات والمعاملات والأرصدة والبيانات المكملة لها وأية استفسارات من الحكومة الطالبة أو ممثليها.

 

ولتمكين الشركة من الامتثال للقوانين الضريبية الأجنبية التي قد يكون العميل خاضعاً لها، فإن العميل يتعهد بأن يزود الشركة بأية مستندات تطلبها الحكومة الأجنبية أو ممثلوها، كما يوافق - أيضاً - على تقديم و/أو تحديث المستندات المشار إليها أعلاه كلما كان ذلك مطلوباً بموجب الشروط والأحكام التي تضعها السلطة الضريبية الأجنبية التي قد يكون خاضعاً لها.

 

يفوض العميل الشركة بأن تقوم بتزويد أو الحصول من بنك الكويت الوطني أو أي من شركاته التابعة على معلومات أو مستندات تتعلق بالعميل إلى الحد الذي يكون فيه العميل عميلاً لبنك الكويت الوطني أو شركاته التابعة بما ومعيار الإبلاغ  (FATCA ) (W9) بالإضافة الى نموذج   في ذلك على سبيل المثال لا الحصر نسخ من نماذج «اعرف عميلك»، نماذج التصديق الذاتي التي تتعلق بالقانون الأمريكي للامتثال الضريبي للحسابات الأجنبي للامتثال الضريبي للحسابات الأجنبية وغيرها من مستندات الهوية، كشوف الحسابات 

وغيرها من مستندات الهوية، كشوف الحسابات والمستندات المؤيدة لمصدر الثروة أو الدخل أو أي مستندات أخرى  المشترك CRS.

  

يفهم العميل ويوافق على أن هذا الإقرار نهائي ولا يجوز الإلغاء أو الرجوع أو التعديل.`;
