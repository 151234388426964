import React from "react";
import MainLayout from "../../layouts/main-layout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../widgets/buttons/primary-button";
import infoIcon from "../../assets/icons/info-icon.svg";
import styles from "./not-renewal.module.scss";

const NotRenewal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const renderFooter = () => {
    const onContinue = () => {
      navigate("/", {
        replace: true,
      });
    };
    return (
      <PrimaryButton onClick={onContinue} data-testid={"continue"}>
        {t("continue")}
      </PrimaryButton>
    );
  };

  return (
    <MainLayout footer={renderFooter}>
      <div className={styles.container}>
        <img className={styles.imageStyle} src={infoIcon} alt="Success" />
        <span className={styles.title}>{t("onboardingCivilId")}</span>
        <span className={styles.subtitleMessage}>
          {t("onboardingCivilIDdescription")}
        </span>
      </div>
    </MainLayout>
  );
};

export default NotRenewal;
