import React from "react";
import Step from "./components/step";

const TimeLine = ({ steps, documentTabSkip }) =>
  steps.map((step, index) => (
    <Step
      key={index}
      step={step}
      lastStep={index === steps.length - 1}
      documentTabSkip={documentTabSkip}
    />
  ));

export default TimeLine;
