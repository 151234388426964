import { locale } from "./localiztion";
import {
  brokerageEums,
  internationalBrokerageTypesEnums,
} from "./enums/general";
import { languageEnum } from "./enums/language-enum";

export const apiUrl = process.env.REACT_APP_API_URL;
export const languageOptions = [
  { value: languageEnum.english, label: "EN", optionLabel: "English (EN)" },
  { value: languageEnum.arabic, label: "ع", optionLabel: "عربي (ع)" },
];

export const brokerageOptions = [
  { value: brokerageEums.REGIONAL_BROKERAGE_RBU, label: "regionalMarkets" },
  {
    value: brokerageEums.INTERNATIONAL_BROKERAGE,
    label: "internationalMarkets",
  },
];

export const alertMessagesMapping = {
  [locale.issueEncountered]: "issueEncountered",
  [locale.kycExpired]: "cidOrKycExpired",
  [locale.alreadyOnboarded]: "alreadyOnboarded",
  [locale.invalidOTP]: "invalidOTP",
  [locale.expiredOTP]: "expiredOTP",
  [locale.maxAttempts]: "maxAttempts",
  [locale.maxAttemptsArabic]: "maxAttempts",
  [locale.tooManyRequestMessage]: "maxAttempts",
  [locale.validSerialNumber]: "enterValidSerialNumber",
  [locale.issueInCivilId]: "issueEncountered",
  [locale.somethingWentWrongMessage]: "somethingWentWrong",
  [locale.issueInCivilId2]: "contactBank",
};

export const internationalTypeOptions = [
  { value: internationalBrokerageTypesEnums.CASH, label: "cashAccounts" },
  {
    value: internationalBrokerageTypesEnums.MARGIN,
    label: "marginAccounts",
  },
];

export const availableMediumsEnum = {
  SMS: "SMS",
  EMAIL: "EMAIL",
};

export const acceptedFileTypes =
  "image/png, image/jpg, image/jpeg, application/pdf";

export const allowedFileTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

export const acceptedCivilIdFileTypes = "image/png, image/jpg, image/jpeg";

export const allowedCivilIdFileTypes = ["image/png", "image/jpg", "image/jpeg"];

export const customerRiskEnums = {
  LOW: "low",
  HIGH: "high",
};

export const returnReasons = {
  KYC_AND_DOCUMENTS: "kyc_and_documents",
  SUPPORTING_DOCUMENTS: "supporting_documents",
};
