import React from "react";
import successIcon from "../../../assets/icons/sucess-icon.svg";
import SuccessPage from "../../../components/success-component";
import { useTranslation } from "react-i18next";
import {
  emailAddressDetailsRenewalRoute,
  journeyTimeLineRenewalRoute,
} from "../../../routes/routes.const";

const OTPSuccessRenewal = () => {
  const { t } = useTranslation("common");
  return (
    <SuccessPage
      icon={successIcon}
      title={t("successOTP")}
      subTitle={t("verifiedMessage")}
      emailConfiramtionRoute={emailAddressDetailsRenewalRoute}
      journeyTimelineSuccessRoute={journeyTimeLineRenewalRoute}
    />
  );
};

export default OTPSuccessRenewal;
