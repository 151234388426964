import { uploadedFileValidations } from "../../utils/validation/validation-messages";

export const validateUploadedFile = (
  file = "",
  maxSizeInBytes = null,
  allowedFormats = []
) => {
  if (!file) {
    return {
      isValid: false,
      error: uploadedFileValidations.noFileSelected,
    };
  }

  if (!allowedFormats.includes(file.type)) {
    return {
      valid: false,
      error: uploadedFileValidations.unSupportedUploadedFileFormat2,
    };
  } else if (file.size > maxSizeInBytes) {
    return {
      isValid: false,
      error: uploadedFileValidations.exceedsSizeLimit20,
    };
  } else {
    return {
      isValid: true,
      error: "",
    };
  }
};
