import React, { useState, useEffect } from "react";
import styles from "./terms-and-conditions.module.scss";
import closeIcon from "../../assets/icons/close-icon.svg";
import { useTranslation } from "react-i18next";
import "../../../src/theme/spacing.scss";
import { termsinArPart, termsinEnPart } from "./locale";

const TermsAndConditionsModal = ({ handleCloseClick }) => {
  const { i18n, t } = useTranslation("common");
  const [direction, setDirection] = useState("rtl");

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    setDirection(dir);
  }, [i18n, i18n.language]);

  const getDirection = () =>
    direction === "ltr"
      ? {
          right: "var(--extra-large-spacing)",
        }
      : {
          left: "var(--extra-large-spacing)",
        };

  return (
    <div
      className={styles.overlay}
      onClick={handleCloseClick}
      data-testid={"terms-and-condition"}
    >
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <span className={styles.title}>{t("termsAndConditionTitle")}</span>
          <button
            className={styles.closeButton}
            style={getDirection()}
            data-testid="closeIcon"
          >
            <img src={closeIcon} alt="Close" onClick={handleCloseClick} />
          </button>
        </div>
        <div className={styles.containerText}>
          <pre className={styles.contentText}>{termsinEnPart}</pre>
          <pre className={styles.contentTextAr}>{termsinArPart}</pre>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
