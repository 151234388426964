import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { pdfjsConfig } from "./worker-config";
import rightArrow from "../../assets/icons/righ-arrow.svg";
import styles from "./pdf-viewer.module.scss";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsConfig.workerSrc;
const PDFViewer = ({ pdfUrl }) => {
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(0.6);
  const { t } = useTranslation("common");
  const [isErrorExists, setIsErrorExists] = useState(false);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const onPageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const onContractLoad = () => {
    if (isErrorExists) {
      setIsErrorExists(false);
    }
  };

  const onContractLoadError = () => {
    setIsErrorExists(true);
  };

  const handleNextPage = (e) => {
    e.stopPropagation();
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
    }
  };

  const handlePreviousPage = (e) => {
    e.stopPropagation();
    const previousPage = currentPage - 1;
    if (previousPage > 0) {
      setCurrentPage(previousPage);
    }
  };

  const displayContract = () => (
    <div className={styles.pdfViewer}>
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onContractLoadError}
        onLoad={onContractLoad}
        error={null}
      >
        <Page
          pageNumber={currentPage}
          scale={scale}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
      <div className={styles.paginationContainer}>
        <img
          onClick={handlePreviousPage}
          src={rightArrow}
          className={styles.arrowLeft}
          alt="left"
        />
        <span className={styles.pageNumber}>
          {" "}
          {currentPage} {t("of")} {totalPages}{" "}
        </span>

        <img
          onClick={handleNextPage}
          className={styles.arrowRight}
          src={rightArrow}
          alt="right"
        />
      </div>
    </div>
  );

  return <> {isErrorExists ? <></> : displayContract()}</>;
};

export default PDFViewer;
