import React, { useState } from "react";
import styles from "../info-box.module.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InfoRenewalBox = ({
  title,
  subTitle,
  beforeDescription = "",
  description = "",
  color,
  borderColor,
}) => {
  const { t } = useTranslation("common");
  const [isViewMore, setIsViewMore] = useState(false);

  return (
    <div
      className={`${styles.containerWithShadow}`}
      style={{ borderColor: borderColor }}
    >
      <span className={`${styles.title}`} style={{ color: color }}>
        {title}
      </span>
      <div className={styles.subTitleContainer}>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}

        <div className={styles.subTitle}> {beforeDescription}</div>
        {description && (
          <>
            <div
              className={styles.description}
              style={{
                WebkitLineClamp: isViewMore ? 50 : 2,
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("descriptionLabel")}
              </span>{" "}
              {description}
            </div>

            <span
              className={styles.viewMore}
              onClick={() => {
                setIsViewMore(!isViewMore);
              }}
            >
              {description.length > 119 ? (
                isViewMore && description.length > 200 ? (
                  t("viewLess1")
                ) : (
                  t("viewMore1")
                )
              ) : (
                <></>
              )}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

InfoRenewalBox.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  beforeDescription: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
};

export default InfoRenewalBox;
