import React from "react";
import styles from "./step.module.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Chip from "../../../chip";

const Step = ({ step, lastStep, documentTabSkip }) => {
  const { t } = useTranslation("common");
  const getStepSubtitle = () => {
    if (documentTabSkip && step.subTitleForSkip) {
      return t(`${step.subTitleForSkip}`);
    } else if (step.isDone && step.subTitleDone) {
      return t(`${step.subTitleDone}`);
    } else {
      return t(`${step.subTitle}`);
    }
  };
  return (
    <div className={styles.container} id={`${step.title}`}>
      <div className={styles.innerContainer1}>
        <img
          data-testid={`test-${step.title}`}
          src={step.isDone ? step.doneIcon : step.icon}
          width={38}
          height={38}
          alt={"icon"}
        />
        {!lastStep && <div className={styles.verticalLine} />}
      </div>

      <div className={styles.innerContainer2}>
        {step.optional ? (
          <Chip text={t("optional")} backgroudColor={"#F4F4F4"} />
        ) : (
          <></>
        )}

        <span className={styles.title}>
          {step.isDone && step.titleDone
            ? t(`${step.titleDone}`)
            : t(`${step.title}`)}
        </span>
        <span className={styles.subTitle}>{getStepSubtitle()}</span>
      </div>
    </div>
  );
};

export default Step;
Step.propTypes = {
  step: PropTypes.object.isRequired,
  lastStep: PropTypes.bool.isRequired,
  documentTabSkip: PropTypes.bool.isRequired,
};
