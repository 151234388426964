import React from "react";
import styles from "./secondary-button.module.scss";

const SecondaryButton = (props) => (
  <button {...props} className={styles.primaryButton}>
    {props.children}
  </button>
);

export default SecondaryButton;
