export const PutCustomerOrderKycUrl = (orderId) =>
  `/v2/web-onboarding/customer/order/${orderId}/kyc`;
export const GetCustomerOrderKycUrl = (orderId, orderKycId) =>
  `/v2/web-onboarding/customer/order/${orderId}/kyc/${orderKycId}`;
export const PostCustomerUrl = "/v2/web-onboarding/customer";
export const PostCustomerOrderUrl = `/v2/web-onboarding/customer/order`;
export const PutCustomerOrderUrl = (orderId) =>
  `/v2/web-onboarding/customer/order/${orderId}`;
export const GetCustomerUrl = `/v2/web-onboarding/customer`;
export const GetCustomerOrderUrl = (orderId) =>
  `/v2/web-onboarding/customer/order/${orderId}`;
export const GetCountriesAndCitiesUrl =
  "/v1/web-onboarding/customers/kyc/countries";
export const GetKycFieldsUrl = "/v1/web-onboarding/customers/kyc/fields";
export const PutCutomerOrderBrokerage = (orderId, orderBrokerageId) =>
  `/v2/web-onboarding/customer/order/${orderId}/brokerage/${orderBrokerageId}`;

export const PutEmailAndLanguage = `/v2/web-onboarding/customer`;

export const AuthorizeCustomer = "/v1/web-onboarding/auth/token ";
export const GetCustomerOrderValidationUrl = (orderId, orderKycId) =>
  `/v2/web-onboarding/customer/order/${orderId}/kyc/${orderKycId}/validation`;

export const SendAndResendOtpUrl = "v2/web-onboarding/auth/otp/renewal/send";
export const GetCustomerOrderKycUrlV2 = (orderId, orderKycId) =>
  `/v2/web-onboarding/customer/order/${orderId}/kyc/${orderKycId}`;
export const PutCustomerOrderKycUrlV2 = (orderId) =>
  `/v2/web-onboarding/customer/order/${orderId}/kyc`;

export const GetCustomerStatusUrl = `/v2/web-onboarding/customer/status`;

export const GetCustomerRiskUrl = (orderKycId) =>
  `/v1/web-onboarding/client-risk/${orderKycId}`;

export const PostCustomerRiskUrl = (orderKycId) =>
  `/v1/web-onboarding/client-risk/${orderKycId}`;

export const SubmissionToAdminUrl = (orderId) =>
  `v2/web-onboarding/customer/order/${orderId}/submission`;

export const GetAllCustomerDocuments = (orderId) =>
  `v2/web-onboarding/customer/order/${orderId}/documents`;

export const DeleteDocument = (orderId, documentId) =>
  `/v2/web-onboarding/customer/order/${orderId}/document/${documentId}`;

export const GenerateClientDocuments = (orderId) =>
  `/v1/web-onboarding/customer/order/${orderId}/document/generate/auto`;
