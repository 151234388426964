import axiosInstance from "../../network/axios-instance";
import { serializeCustomerMediumsResponse } from "../../serializer";
import {
  GetCustomerStatusUrl,
  GetCustomerStatusUrlRenewal,
} from "./civil-id-details-urls.const";

export const getCustomerStatus = async (payload) => {
  try {
    const response = await axiosInstance.post(GetCustomerStatusUrl, payload);
    return serializeCustomerMediumsResponse(response.data) || {};
  } catch (exception) {
    throw exception;
  }
};

export const getCustomerMediums = async (payload) => {
  try {
    const response = await axiosInstance.get(GetCustomerStatusUrlRenewal, {
      params: payload,
    });
    return serializeCustomerMediumsResponse(response.data) || {};
  } catch (exception) {
    throw exception;
  }
};
