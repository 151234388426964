export const landingRoute = "/";
export const onboarding = "/on-boarding";
export const otpVerification = "/otp-verification";
export const otpVerificationRenewal = "/renewal/otp-verification";
export const productsListing = "/products-list";
export const civildIDetailsRoute = "/civil-id-details";
export const civildIDetailsRenewalRoute = "/renewal/civil-id-details";
export const phoneNumberDetailsRoute = "/phone-number-details";
export const otpSuccessRoute = "/verified-otp";
export const emailAddressDetailsRoute = "/email-address";
export const journeyTimeLineRoute = "/journey-timeline";
export const kycDetailsRoute = "/kyc-details";
export const identityVerificationRoute = "/identity-verification";
export const reviewAndSignRoute = "/review-and-sign";
export const personalInfoStepRoute = "/personal-info-step";
export const employmentInfoStepRoute = "/employment-info-step";
export const contactInfoStepRoute = "/contact-info-step";
export const kycSummaryStepRoute = "/kyc-summary-step";
export const kycRenewalSummaryStepRoute = "/renewal/kyc-summary-step";
export const additionalInfoStepRoute = "/additional-info-step";
export const incomeAndWealthInfoStepRoute = "/income-wealth-info-step";
export const internationalBrokerageRoute = "/international-brokerage";
export const applicationSubmittedRoute = "/application-submitted";
export const contactSupportRoute = "/contact-support";
export const personalRenewalInfoStepRoute = "/renewal/personal-info-step";
export const employmentRenewalInfoStepRoute = "/renewal/employment-info-step";
export const contactRenewalInfoStepRoute = "/renewal/contact-info-step";
export const additionalRenewalInfoStepRoute = "/renewal/additional-info-step";
export const incomeAndWealthRenewalInfoStepRoute =
  "/renewal/income-wealth-info-step";
export const notRenewalRoute = "/renewal/onboarding-client";
export const otpSuccessRenewalRoute = "/renewal/verified-otp";
export const journeyTimeLineRenewalRoute = "/renewal/journey-timeline";
export const identityVerificationRenewalRoute =
  "/renewal/identity-verification";
export const emailAddressDetailsRenewalRoute = "/renewal/email-address";
export const uploadAdditionalDocumentsRoute =
  "/renewal/upload-additional-documents";
export const applicationSubmittedRenewalRoute =
  "/renewal/application-submitted";
